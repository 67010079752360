import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { customersUrl: String, citiesUrl: String };

  static targets = [
    'fetchBtn',
    'notYouQuestion',
    'alreadyExistingPhoneNumber',
    'refreshSpinner',
    'fetchForm',
    'detailsForm',
  ];

  connect() {
    console.log('HELLO!');
  }

  async fetchCustomer(postalCode, phoneNumber) {
    const url = new URL(this.customersUrlValue);
    const params = { phone_number: phoneNumber, postal_code: postalCode };
    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

    const response = await fetch(url);
    const { customer, errors } = await response.json();

    this.element.dispatchEvent(
      new CustomEvent('customer-details:fetched', {
        bubbles: true,
        detail: { customer, errors, phoneNumber },
      }),
    );

    if (errors) {
      const alreadyExistingPhoneNumber =
        errors['customer.phone_numbers'] &&
        errors['customer.phone_numbers'].includes('exists on different postal code');

      if (alreadyExistingPhoneNumber) {
        Array.from(this.fetchFormTarget.elements).forEach((element) => {
          element.classList.add('is-invalid');
        });

        this.alreadyExistingPhoneNumberTarget.classList.remove('d-none');

        this.enableFetchBtn();
        return;
      }
    }

    this.fetchFormTarget.classList.add('d-none');
    this.detailsFormTarget.classList.remove('d-none');

    if (customer && customer.id) {
      this.fillCustomerDetails(customer);
      this.disableCustomerDetailsForm();
    } else {
      this.enableCustomerDetailsForm();
    }

    this.element.dispatchEvent(
      new CustomEvent('customer-details:filled', {
        bubbles: true,
        detail: { customer, phoneNumber },
      }),
    );

    // Scroll to bottom
    window.scrollTo(0, document.body.scrollHeight);
  }

  enableCustomerDetailsForm() {
    Array.from(this.detailsFormTarget.elements).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.readOnly = false;
    });

    this.notYouQuestionTarget.classList.add('d-none');
  }

  enableFetchBtn() {
    this.fetchBtnTarget.disabled = false;
    this.fetchBtnTarget.querySelector('.btn-label').classList.toggle('d-none');
    this.fetchBtnTarget.querySelector('.spinner-grow').classList.toggle('d-none');
  }

  disableFetchBtn() {
    this.fetchBtnTarget.disabled = true;
    this.fetchBtnTarget.querySelector('.btn-label').classList.toggle('d-none');
    this.fetchBtnTarget.querySelector('.spinner-grow').classList.toggle('d-none');
  }

  disableCustomerDetailsForm() {
    Array.from(this.detailsFormTarget.elements).forEach((element) => {
      // eslint-disable-next-line no-param-reassign
      element.readOnly = true;
    });

    this.notYouQuestionTarget.classList.remove('d-none');
  }

  fillCustomerDetails(customer) {
    const formElements = this.detailsFormTarget.elements;

    // Send GA event
    window.gtag('set', { user_id: customer.id });
    window.gtag('event', 'found_customer', {
      event_category: 'engagement',
    });

    formElements['customer-id'].value = customer.id;
    formElements['customer-details-id'].value = customer.id;
    formElements['customer-details-postal-code'].value = customer.postalCode;
    formElements['customer-details-email'].value = customer.email;
    formElements['customer-details-first-name'].value = customer.firstName;
    formElements['customer-details-last-name'].value = customer.lastName;
    formElements['customer-details-address'].value = customer.address;
    formElements['customer-details-city-name'].value = customer.city.name;

    if (customer.phoneNumbers.length > 0) {
      formElements['customer-details-phone-number-id'].value = customer.phoneNumbers[0].id;
      formElements['customer-details-phone-number-number'].value = customer.phoneNumbers[0].number;
    }

    this.element.dispatchEvent(
      new CustomEvent('customer-details:city-validated', {
        bubbles: true,
        detail: { city: customer.city },
      }),
    );
  }

  fetchCustomerDetails() {
    const formElements = this.fetchFormTarget.elements;
    const postalCode = formElements.postal_code.value;
    const phoneNumber = formElements.phone_number.value;

    if (!postalCode || !phoneNumber) return;

    // Disable button
    this.disableFetchBtn();

    // Copy over values to details form
    this.detailsFormTarget.elements['customer-details-postal-code'].value = postalCode;
    this.detailsFormTarget.elements['customer-details-phone-number-number'].value = phoneNumber;

    this.fetchCustomer(postalCode, phoneNumber);
  }

  fetchCustomerDetails2() {
    const formElements = this.detailsFormTarget.elements;
    const customerId = formElements['customer-details-id'].value;
    const postalCode = formElements['customer-details-postal-code'].value;
    const phoneNumber = formElements['customer-details-phone-number-number'].value;

    if (customerId !== '' || !postalCode || !phoneNumber) return;

    this.disableCustomerDetailsForm();

    this.refreshSpinnerTarget.classList.remove('d-none');

    this.fetchCustomer(postalCode, phoneNumber).then(() => {
      this.refreshSpinnerTarget.classList.add('d-none');
    });
  }

  async fetchCity() {
    const formElements = this.detailsFormTarget.elements;
    const cityName = formElements['customer-details-city-name'].value;
    const params = { city_name: cityName };
    const url = new URL(this.citiesUrlValue);

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCSRFToken(),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });

    const { city } = await response.json();

    this.element.dispatchEvent(
      new CustomEvent('customer-details:city-validated', {
        bubbles: true,
        detail: { city },
      }),
    );
  }
}
