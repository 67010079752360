import { Controller } from '@hotwired/stimulus';
import consumer from '../consumer';

export default class extends Controller {
  static targets = ['boxQuantityInput', 'quantityInput', 'boxesLeftLabel'];

  static values = {
    quantityPerBox: Number,
    price: Number,
    productId: Number,
    productName: String,
    pricePerKgExclVat: Number,
    vatPerKg: Number,
    previousNumberOfBoxes: Number,
  };

  connect() {
    this.element[this.identifier] = this;

    const event = document.createEvent('CustomEvent');
    event.initCustomEvent('product-card:connected', true, true, null);
    this.element.dispatchEvent(event);

    const campaignToken = window.location.pathname.split('/')[1];
    this.channel = consumer.subscriptions.create(
      { channel: 'CampaignChannel', campaign_token: campaignToken },
      {
        connected() {},
        disconnected() {},
        received: this.cableReceived.bind(this),
      },
    );
  }

  cableReceived(data) {
    const { productId, numberOfBoxesLeft } = data;

    if (productId !== this.productIdValue) return;

    if (numberOfBoxesLeft > 0) {
      this.boxQuantityInputTargets.forEach((input) => {
        input.disabled = false;
        input.setAttribute('max', numberOfBoxesLeft);
      });

      this.boxesLeftLabelTargets.forEach((label) => {
        window.animateCSS(label, 'heartBeat').then((e) => {
          // eslint-disable-next-line no-param-reassign
          e.querySelector('small').innerHTML = `${numberOfBoxesLeft} ${
            numberOfBoxesLeft === 1 ? 'låda' : 'lådor'
          } kvar`;
        });
      });
    } else {
      this.boxQuantityInputTargets.forEach((input) => (input.disabled = true));

      this.boxesLeftLabelTargets.forEach((label) => {
        label.classList.add('text-danger');
        label.classList.remove('text-success');
        window.animateCSS(label, 'heartBeat').then((e) => {
          // eslint-disable-next-line no-param-reassign
          e.querySelector('small').innerHTML = 'SLUTSÅLD!';
        });
      });
    }
  }

  toggleFlip(event) {
    event.preventDefault();

    const { productId: id, productName: name, pricePerKgExclVat } = this.element.dataset;

    window.gtag('event', 'view_item', { id, name, price: pricePerKgExclVat });

    this.element.classList.toggle('flipped');
  }

  get previousQuantityInKg() {
    return this.previousNumberOfBoxesValue * this.quantityPerBoxValue;
  }

  get amount() {
    return this.pricePerKgExclVatValue * this.quantityInputTarget.value + this.vat;
  }

  get vat() {
    return this.vatPerKgValue * this.quantityInputTarget.value;
  }

  updateQuantity(event) {
    const { target } = event;
    const numberOfBoxes = parseInt(target.value || 0, 10);
    const quantityInKg = numberOfBoxes * this.quantityPerBoxValue;
    const quantityChange = quantityInKg - this.previousQuantityInKg;

    // Update sibling input and set previous value
    // eslint-disable-next-line no-return-assign
    this.boxQuantityInputTargets.forEach((e) => (e.value = target.value));
    this.previousNumberOfBoxesValue = target.value;

    // Update hidden input with real quantity
    this.quantityInputTarget.value = quantityInKg;

    if (numberOfBoxes > this.boxesInStockValue) {
      this.showReserveOrderInfoModal();
    }

    // Send GA event
    window.gtag('event', quantityChange > 0 ? 'add_to_cart' : 'remove_from_cart', {
      value: Math.abs(quantityChange) * this.pricePerKgExclVatValue,
      currency: 'SEK',
      items: [
        {
          id: this.productIdValue,
          name: this.productNameValue,
          price: this.priceValue,
          quantity: Math.abs(quantityChange),
        },
      ],
    });
  }
}
