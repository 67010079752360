import { Controller } from '@hotwired/stimulus';
import { sumBy } from 'lodash';

export default class extends Controller {
  static targets = [
    'boxQuantityInput',
    'productCard',
    'vatText',
    'totalText',
    'customerDetailsForm',
    'deliveryMethod',
    'deliveryMethodMessage',
  ];

  validateForm(event) {
    if (!this.element.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.element.classList.add('was-validated');
  }

  updateQuantity() {
    // Make sure at least one product has a quantity
    const allQuantitiesEmpty = this.boxQuantityInputTargets.every(
      (input) => !input.value || input.value === '0',
    );

    this.boxQuantityInputTargets.forEach((input) => {
      if (input.value === '0') input.value = ''; // eslint-disable-line no-param-reassign
      input.required = allQuantitiesEmpty; // eslint-disable-line no-param-reassign
    });

    this.totalTextTarget.innerText = sumBy(
      this.productCardTargets,
      (t) => t['product-card'].amount,
    ).toLocaleString('sv-SE');

    this.vatTextTarget.innerText = sumBy(
      this.productCardTargets,
      (t) => t['product-card'].vat,
    ).toLocaleString('sv-SE');
  }

  filterPickupPoints(event) {
    if (event.detail.city) {
      const { id: cityId, regionId } = event.detail.city;

      this.deliveryMethodMessageTarget.classList.add('d-none');

      this.deliveryMethodTargets.forEach((t) => {
        if (
          t.dataset.regionId === regionId.toString() &&
          (!t.dataset.cityId || t.dataset.cityId === cityId.toString())
        ) {
          t.classList.remove('d-none');
        } else {
          t.classList.add('d-none');
        }
      });

      if (this.deliveryMethodTargets.every((t) => t.classList.contains('d-none'))) {
        this.deliveryMethodMessageTarget.classList.remove('d-none');
      }
    } else {
      this.deliveryMethodTargets.forEach((t) => {
        t.classList.add('d-none');
      });

      this.deliveryMethodMessageTarget.classList.remove('d-none');
    }
  }
}
