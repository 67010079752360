import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.element.addEventListener('ajax:error', this.handleValidationError.bind(this));
    this.element.addEventListener('ajax:success', this.handleSuccessfulRegistration.bind(this));
    this.formTarget.addEventListener(
      'customer-details:fetched',
      this.handleFetchedCustomer.bind(this),
    );
  }

  handleFetchedCustomer(event) {
    const { errors, customer, phoneNumber } = event.detail;

    if (errors) {
      const alreadyExistingPhoneNumber =
        errors['customer.phone_numbers'] &&
        errors['customer.phone_numbers'].includes('exists on different postal code');

      const notFound =
        errors['customer.phone_numbers'] && errors['customer.phone_numbers'].includes('not found');

      if (alreadyExistingPhoneNumber) {
        this.showMessage(
          'Telefonnumret är redan registrerat',
          `Telefonnumret ${phoneNumber} är registrerat på ett annat postnummer hos oss.
              Kontrollera telefonnummer och postnummer eller kontakta oss så hjälper vi dig
              vidare! Kontaktuppgifter hittar du här på sidan.`,
        );
      } else if (notFound) {
        document.getElementById('fetch-customer-details').classList.add('d-none');
        document.getElementById('customer-details').classList.remove('d-none');
        this.formTarget.querySelector("input[type='submit']").classList.remove('d-none');
      }
    } else if (customer && customer.id) {
      this.showMessage(
        'Du är redan registrerad!',
        'Vi har redan ditt nummer inlagt och meddelar dig nästa gång vi har en leverans på gång till din ort. Misstänker du att du inte får SMS:en? Hör av dig till oss så hjälper vi till! Kontaktuppgifter hittar du här på sidan.',
      );
    }
  }

  handleSuccessfulRegistration() {
    this.showMessage(
      'Tack och välkommen!',
      'Vi har registrerat dig i vårt system och kommer att meddela så fort vi har en leverans på gång på din ort. Detta sker oftast ungefär en gång per månad. Läs gärna igenom hur det fungerar här ovan på sidan och tveka inte att kontakta oss om något är oklart!',
    );
  }

  handleValidationError(event) {
    const { errors } = event.detail[0];

    const hasMissingCity = errors.city && errors.city[0].includes('inte börjat leverera');

    if (hasMissingCity) {
      this.showMessage('Vi är ledsna!', errors.city[0]);
    }
  }

  validateForm(event) {
    if (!this.formTarget.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.formTarget.classList.add('was-validated');
  }

  showMessage(title, body) {
    $(this.element.querySelector('h3')).text(title);
    $(this.element.querySelector('.card-body')).html(body).addClass('text-center');
    $('#greeting').removeClass('d-none').addClass('d-flex');
  }
}
