import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['form'];

  connect() {
    this.element.addEventListener('ajax:error', this.handleError.bind(this));
    this.element.addEventListener('ajax:success', this.handleSuccess.bind(this));
  }

  handleError() {
    this.showMessage('warning', 'Tyvärr!');
  }

  handleSuccess() {
    this.showMessage(
      'success',
      'Yes, vi levererar till din ort! Om du inte redan har registrerat dig så kan du göra det längre ner på sidan så kontaktar vi dig nästa gång vi har en leverans på gång!',
    );
  }

  validateForm(event) {
    if (!this.formTarget.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }

    this.formTarget.classList.add('was-validated');
  }

  showMessage(type, message) {
    this.element.innerHTML = `
      <div class="alert alert-${type} mt-3 mb-0">
        ${message}
      </div>
    `;
  }
}
