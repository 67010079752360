/* eslint no-console:0 */
import '../stylesheets/public.scss';

import 'jquery';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';
import 'scrollpos-styler';

window.jQuery = jQuery;
window.$ = $;

Rails.start();
Turbolinks.start();

const application = Application.start();
const context = require.context('./public/controllers', true, /\.js$/);
application.load(definitionsFromContext(context));

window.animateCSS = (element, animation, prefix = 'animate__') =>
  new Promise((resolve) => {
    const animationName = `${prefix}${animation}`;
    element.classList.add(`${prefix}animated`, animationName);

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd() {
      element.classList.remove(`${prefix}animated`, animationName);
      resolve(element);
    }

    element.addEventListener('animationend', handleAnimationEnd, { once: true });
  });

window.getCSRFToken = () => {
  const csrfToken = document.querySelector("[name='csrf-token']");

  if (csrfToken) {
    return csrfToken.content;
  }
  return null;
};
