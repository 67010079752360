import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { enableEvents: Array, disableEvents: Array };

  connect() {
    this.element.classList.add('d-none');
  }

  enable() {
    this.element.classList.remove('d-none');
  }

  disable() {
    this.element.classList.add('d-none');
  }
}
